import React, { useEffect, useRef } from "react";

const AdsenseAd = () => {
  const adRef = useRef(null); // Create a ref to ensure the AdSense script is only pushed once.

  useEffect(() => {
    // Check if adsbygoogle is defined and has not already pushed the ad for this component.
    if (window.adsbygoogle && adRef.current && !adRef.current.hasAd) {
      try {
        window.adsbygoogle.push({});
        adRef.current.hasAd = true; // Flag to prevent multiple ads from being pushed.
      } catch (e) {
        console.error("AdSense error", e);
      }
    }
  }, []);

  return (
    <div style={{ textAlign: "center", margin: "20px 0" }}>
      <ins
        ref={adRef} // Attach ref to the ins element
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3940256099942544" // Test Publisher ID
        data-ad-slot="1234567890" // Test Ad Slot ID
        data-ad-format="auto"
        data-full-width-responsive="true"
        data-adtest="on"
      ></ins>
    </div>
  );
};

export default AdsenseAd;
